
/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/app/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {    
    auth: {
      clientId:environment.clientId, //"11c993fb-0e51-4dfd-aa54-1fa15f532685",
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      authority: "https://login.microsoftonline.com/"+environment.tenentId, //"https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment
      redirectUri: '/',
      postLogoutRedirectUri: '/',      
      //clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
        /**
         * Below you can configure MSAL.js logs. For more information, visit:
         * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
         */
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiDBot: {
        endpoint:environment.APIBaseUrl, //"https://oaiapidev.azurewebsites.net/",
        scopes: {
            //read:[environment.APIScopeURL]//["api://9afbb83c-a74f-4203-b048-4a8e6308dc8e/user_impersonation"],
             //write: ["api://Enter_the_Web_Api_Application_Id_Here/TodoList.ReadWrite"]
             write: [environment.APIScopeURL]
        }
    },
    uploadDocument: {
        endpoint: environment.uploadDocumentURL,
        scopes: {
            //read:[environment.APIScopeURL]//["api://9afbb83c-a74f-4203-b048-4a8e6308dc8e/user_impersonation"],
             //write: ["api://Enter_the_Web_Api_Application_Id_Here/TodoList.ReadWrite"]
             write: [environment.APIScopeURL]
        }
    },
    resetUploadedDocument: {
        endpoint: environment.refreshDocumentURL,
        scopes: {
            read: [environment.APIScopeURL]
        }
    },
    processUploadedDocument: {
        endpoint: environment.processDocumentURL,
        scopes: {
            read: [environment.APIScopeURL]
        }
    },
    searchSummaryDocument : {
        endpoint: environment.summaryDocumentURL,
        scopes: {
            write: [environment.APIScopeURL]
        }
    },
    orange : {
        endpoint: environment.orangeProjectURL,
        scopes:{
            write: [environment.APIScopeURL]
        }
    },
    verifyuser : {
        endpoint: environment.verifyUserURL,
        scopes:{
            write: [environment.APIScopeURL] 
        }
    }
    
}

export const loginRequest = {
    scopes: []
};